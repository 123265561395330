import React from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import { NavBar } from "../components/Navbar";
import { Footer } from "./Footer";
import { Card } from "./ProjectCard";
import logoBlack from "../assets/img/logo-black.svg";
import { ArrowLeftShort, ChevronLeft, ChevronRight, ArrowRightShort } from "react-bootstrap-icons";
import Diagram0 from "../assets/img/cloud-based-trading-diagram.webp";
import Diagram1 from "../assets/img/fixed-income-analytics-diagram.webp";
import Diagram2 from "../assets/img/electronic-trade-diagram.webp";
import Diagram3 from "../assets/img/consolidated-tape-analytics-diagram.webp";
import Diagram4 from "../assets/img/retail-bond-trading-diagram.webp";
import Diagram5 from "../assets/img/lite-equity-oms-diagram.webp";
import Diagram6 from "../assets/img/global-corporate-action-services-diagram.webp";
import Diagram7 from "../assets/img/global-corporate-event-diagram.webp";
import Diagram8 from "../assets/img/global-multi-asset-management-diagram.webp";
import Diagram9 from "../assets/img/global-neutral-trading-diagram.webp";
import Diagram10 from "../assets/img/advanced-trading-systems-diagram.webp";
import Diagram11 from "../assets/img/global-inter-dealer-trading-diagram.webp";

export const ProjectDetail = () => {
  // Project list state
  const projectList = [
    {
      id: 0,
      Name: "Cloud Based Fixed Income Trading System",
      Headline: [
        "Built a real-time, robust, reliable, and efficient cloud-based fixed-income market data and trading system for an innovative financial portfolio management company.",
        "The trading system supports fixed income trading of corporates, treasuries, municipal bonds and many other fixed income securities. The system supports trading through various trading protocols such as Central Limit Order Book (CLOB), Request for Quote (RFQ) and Session/Auction based trading to enhance flexibility. It can easily be integrated with other systems through APIs and ensures continuous compliance with regulatory standards.",
      ],
      Highlight: [
        "Continuous Fixed Income Securities Trading",
        "Covers a range of fixed-income securities including governments, corporates, and municipal bonds.",
        "Provides various order types like limit, market and stop to accommodate different trading strategies.",
        "Allows RFQ and RFI-based trading protocols to enhance flexibility.",
        "Connects to a single or multiple ECNs using their Market Data, Trading and Straight Through processing APIs.",
        "Handles 250-500 million+ Fixed Income updates daily, a sustained rate of 10k-12k messages/sec for several minutes, per ECN.",
        "High performance multi ECN fixed income trading system from start to prod ready in cloud in three months.",
        "Robust API to integrate into any existing system to pull in Market Data and be able to trade effectively and efficiently.",
        "Effectively helps manages credit and liquidity risks associated with fixed-income securities.",
        "Provides an intuitive and customizable trading platform.",
        "Easily integrates with other systems through APIs.",
      ],
      Paragraph: ["This comprehensive system aims to help create a robust and efficient trading environment for fixed-income securities for market participants or fixed income analytics and services provider, catering to various market participants.", "Reach out to us for any fixed income data and trading system. We have been implementing fixed income systems including market data analytics, trading, clearing, settlement, reg reporting, PnL, risk, etc., for multiple decades."],
      Diagram: Diagram0,
      associatedFilters: ["Fixed Income"],
      Link: "cloud-fixed-income-trading-system",
      associatedCards: ["Advanced NDF, FX, FX Options, and Interest Rate Trading Systems", "Global Multi-Asset Class Trade Management System", "Global Asset Class Neutral Trading System"],
    },
    {
      id: 1,
      Name: "Comprehensive Fixed Income Market Data and Analytics System",
      Headline: [
        "Architected, designed, and built a fixed income market data and analytics system for global securities. We been enhancing, operating, managing and supporting the system for close to a decade.",
        "The system allows global coverage of fixed-income instruments, including government bonds, corporate bonds, municipal bonds and mortgage-backed securities. The system takes fixed income market data feeds, security information feeds, treasuries feed and interest rate swaps feed to calculate T-spread, Z-Spread, I-Spread, S-Spread, Yield, Yield to Maturity/Call/Put/Worst, BP01, Various Durations, Convexity and other comprehensive analytics. The system can provide data and analytics using API (FIX, MQ, Web API, etc.), Web Front End, Dashboard, Files, and many other mechanisms.",
      ],
      Highlight: [
        "Provide extensive coverage of best-in-class, real-time, corporate bonds, offering a holistic view of the fixed income markets.",
        "Real-time pricing information and historical data, enabling them to track market movements, identify trends, and make informed investment decisions based on both current and past market conditions.",
        "A comprehensive security master is available for Fixed Income with call schedules and Interest rate schedules within the system.",
        "Global coverage, allowing investors to analyze and compare fixed income using different spreads generated for bonds and identify opportunities across different regions and markets.",
        "Analytics system for the corporate bond market that establishes price integrity and facilitates market modernization.",
        "Ensuring data security and providing reliable, accurate information are paramount, given the critical nature of financial data in making investment decisions using calculated risk spreads i.e. T, G, I and Z spread.",
        "System can provide analytic data multiple ways i.e. via FIX protocol, JSON files.",
        "Integration capabilities that allow the incorporation of government treasury market data and swap rates to facilitate calculating different risk spreads in the system.",
        "Operations have access to various dashboards and alerts for monitoring systems and performance.",
      ],
      Paragraph: "",
      Diagram: Diagram1,
      associatedFilters: ["Fixed Income"],
      Link: "comprehensive-fixed-income-system",
      associatedCards: ["Consolidated Tape and Analytics for Fixed Income Trades", "Global Corporate Action System for Prime Broker Services", "Cloud Based Fixed Income Trading System"],
    },
    {
      id: 2,
      Name: "Fixed Income Electronic Post Trade Processing System",
      Headline: [
        "Designed, developed, deployed and manages a comprehensive automated Global Fixed Income Trade Management System.",
        "The system currently connects to all major ECNs (Bondpoint, TMC, Tradeweb Direct, Tradeweb Institutional, BrokerTec, MarketAxess, Muni Broker, Bonds.com, Bloomberg TOMS, and Bloomberg VCON), receives real time trade, cancel and modify notifications, enriches the details based on the client including security classification, method and venue of clearing and sends these trades to clearer(s) in real time, within seconds, for clearing, settlement, and TRACE reporting purposes.",
        "Once a trade is sent for clearing, settlement, and reporting, the system takes the feedback from clearer and regulatory reporting systems to reflect the status in real-time as the information is processed and made available, in an easy way to follow and operate. This helps tremendously in finding failed trades instantaneously, reducing errors and challenges associated with late discovery in clearing up the fails.",
        "A trader can see all his trades, positions, average cost price, realized PnL, and unrealized PnL in real time. A head trader and risk manager has access to all such information including risk in real time across the desk, group and the company.",
        "The system is flexible enough and can further connect to any other trade execution feed from any source, such as dealers or any other execution platform, any additional clearer, etc.",
        "The system is hosted in cloud and is designed for resiliency, performance, and disaster recovery across, process, compute, data center and zone within a region. A plan of resiliency across the region is planned.",
        "The system can be further extended to provide trader and desk-based risk controls.",
        "As with other systems and customers, we run, manage, maintain and operate the systems for full trading hours.",
      ],
      Highlight: [
        "Connected to 11 ECNs for consuming trades in Realtime",
        "Independent modules running in parallel that connect to the trading venues",
        "Central trade processing, and enrichment",
        "Standardized trades for the traders to see on the front end",
        "Custodian Trade enrichment for settlement, clearing and TRACE reporting",
        "Based on real time trades, calculation of avg price, position and PnL happens in real time",
        "Report generation based on trading activity",
        "No restriction on number of operations, middle office, and admins to manage and run the system and operate the business",
      ],
      Paragraph: ["If you are looking for a state-of-the-art full function post trade system, look no further. Contact us today and save hundreds of thousands of dollars in cost and make your front to back office more efficient and less error prone."],
      Diagram: Diagram2,
      associatedFilters: ["Fixed Income"],
      Link: "electronic-post-trade-processing-system",
      associatedCards: ["Lite Equity Order Management System (OMS)", "Advanced NDF, FX, FX Options, and Interest Rate Trading Systems", "Cloud Based Fixed Income Trading System"],
    },
    {
      id: 3,
      Name: "Consolidated Tape and Analytics for Fixed Income Trades",
      Headline: [
        "Built a cloud based comprehensive consolidated tape of fixed income securities trading in the European markets built using trading activities of a large group of market participants.",
        "In this robust and reliable consolidation system, the trading data comes directly from several buy-side financial market participants. The system provides the contributors access to consolidated bond trading data that is easy to understand data. The data can be delivered to a range of global financial market participants, such as dealers, buy side, market data vendors, and others. The data can be delivered using FIX, files, and Business Intelligence Dashboards, etc. The solution is robust, reliable, and highly scalable, built using the latest technologies, and is hosted on the cloud with full disaster recovery and resilience across regions. One of the other key features of the system design is its low cloud operating costs.",
      ],
      Highlight: [
        "Data comes directly from buy-side asset managers - not from trading platforms or exchanges. A unique data streams directly from the sources via SFTP or Email.",
        "Provides data contributors with access to unfiltered, raw, bond trading data. Clear, easy to understand data distribution rules, driven by the market, with no complex waiver or deferral regime.",
        "Leveraged experience in data handling to understand and normalize data from various formats.",
        "Data delivery direct to client via any preferred medium i.e. SFTP / Email / API",
        "Support all kinds of data format customization for clients.",
        "Built using Python, JAVA and MySQL and hosted on AWS.",
        "Having 100% disaster recovery and resiliency across zones and regions.",
        "Enforcing high security standards across the project and infrastructure.",
        "Currently in use by leading asset managers.",
      ],
      Paragraph: "",
      Diagram: Diagram3,
      associatedFilters: ["Fixed Income"],
      Link: "consolidated-tape-and-analytics",
      associatedCards: ["Comprehensive Fixed Income Market Data and Analytics System", "Global Corporate Action System for Prime Broker Services", "Cloud Based Fixed Income Trading System"],
    },
    {
      id: 4,
      Name: "Retail Bond Trading System",
      Headline: [
        "Built a cloud-based core infrastructure that is mobile first and web based fixed income trading system to trade treasuries, corporate bonds, municipal, and other fixed income securities. An easy-to-use user-friendly mobile app allows retail investors, High Net worth Individuals (HNIs), Registered Investment Advisers (RIAs), endowment offices, family offices, etc.",
        "This Retail Bond Trading system allows one to screen bonds based on yield, maturity, sector, rating, and various other parameters. It provides tradable market levels as price or spreads over treasury, and a user can trade bonds directory in the marketplace. The App shows the order status for orders in the marketplace. A portfolio (trade blotter) shows holdings, their values, and gain/loss. The system can be enhanced with news and professional trader-worthy analytics such as various yields, various spreads, various durations, etc.  The system can be enhanced to show historical data, DV01, and other risk and portfolio management functions from a retail investor's perspective.",
        "The system can connect with various Electronic Communication Networks for market data and execution.",
      ],
      Highlight: [
        "A user-friendly interface and customizable dashboards to cater to the diverse preferences and requirements of retail investors, high-net-worth individuals (HNIs), registered investment advisors (RIAs), endowments, and family offices.",
        "Access to real-time market data, historical performance, and in-depth information on a wide range of fixed income instruments, including government bonds, corporate bonds, and municipal bonds.",
        "Robust analytics tools for risk assessment, scenario analysis, and comprehensive risk management, helping users make well-informed investment decisions.",
        "Portfolio tracking and management features that allow users to align investments with their specific goals, risk tolerance, and investment horizon.",
        "Efficient order execution capabilities with various order types, providing flexibility for different trading strategies and preferences.",
        "Integration of educational resources to empower users with knowledge about fixed income markets, investment strategies, and the implications of market events.",
        "Robust security features, including encryption, multi-factor authentication, and secure data storage, to protect user data and transactions.",
        "Seamless integration with financial planning tools to ensure that fixed income investments align with broader financial goals.",
        "Customizable alerts and notifications to keep users informed of market events, portfolio changes, and other relevant updates in real-time.",
      ],
      Paragraph: ["This comprehensive system aims to help create an easy-to-use trading environment for fixed-income securities for retail market participants.", "Reach out to us for any fixed income data and trading system. We have been implementing fixed income systems including market data analytics, trading, clearing, settlement, reg reporting, PnL, risk, etc., for multiple decades."],
      Diagram: Diagram4,
      associatedFilters: ["Fixed Income"],
      Link: "retail-bond-trading",
      associatedCards: ["Global Asset Class Neutral Trading System", "Cloud Based Fixed Income Trading System", "Global Multi-Asset Class Trade Management System"],
    },
    {
      id: 5,
      Name: "Lite Equity Order Management System (OMS)",
      Headline: [
        "Designed, developed, deployed, operating and maintaining a Lite Equity Order Management System (OMS) for a sell side client that allows them to accept FIX order flow from clients and other venues, perform pre-trade checks, route the orders to an Execution management System (EMS) or another OMS, calculate commissions and charges and allocate trades for a faster and smoother clearing and settlement.",
        "The system accepts client orders via FIX and has the ability for a trader to create an order flow, perform company-specific risk, compliance, and limit checks, and then release the orders to a desired OMS/EMS for execution. The system maintains full order and trade status during its lifecycle FIX, downstream and upstream both. A front end shows all order and trading activity and provides controls to manage them. An executed trade gets allocated automatically for seamless clearing, settlement and reporting purposes.",
        "The product was built, certified for execution, and deployed for use by traders and middle office in 18 weeks (about 4-5 months). Check out the details.",
        "Empower Equity Trading Journey with OMS and get trustworthy results.  Contact us for more information today!",
      ],
      Highlight: [
        "Unified platform for equity trading, seamlessly integrating order receipt, routing, execution, and post-trade workflows.",
        "Harnessing the power of the FIX protocol ensures standardized and efficient communication for order representation and execution across the financial ecosystem.",
        "Intelligent routing algorithms optimize order execution by dynamically selecting the best venues based on factors like liquidity, price, and execution speed.",
        "Direct routing capabilities enable fast and efficient access to exchanges and execution venues, reducing latency and enhancing trade execution speed.",
        "Integration with a suite of algorithmic trading strategies empowers traders to execute orders with precision, leveraging sophisticated algorithms tailored for different market conditions.",
        "Real-time market data feeds keep traders informed, providing up-to-the-minute information on asset prices, bid-ask spreads, and trading volumes.",
        "Streamlined post-trade workflows automate trade matching, confirmation generation, settlement, clearing processes, and trade allocation to enhance operational efficiency.",
        "Pre-trade compliance checks ensure regulatory and internal policy adherence, while real-time risk monitoring helps manage exposure and comply with risk limits.",
        "An intuitive dashboard offers a user-friendly experience, allowing traders to efficiently monitor order status, receive alerts, and access real-time analytics.",
        "Robust reporting features, including trade blotters, execution analysis, and custom report generation, empower traders with valuable insights for decision-making.",
        "Workflow automation streamlines post-trade processes, while event notifications keep users informed about significant milestones in the trade lifecycle.",
      ],
      Paragraph: "",
      Diagram: Diagram5,
      associatedFilters: ["Equities"],
      Link: "lite-equity-oms",
      associatedCards: ["Global Asset Class Neutral Trading System", "Cloud Based Fixed Income Trading System", "Global Multi-Asset Class Trade Management System"],
    },
    // {
    //   id: 6,
    //   Name: "Global Corporate Action System for Prime Broker Services",
    //   Headline: ["Built a secure, cloud-based software solution for Global Corporate Action Management for prime broker services."],
    //   Highlight: [
    //     "The system supports ISO corporate action announcement messaging and client proprietary files.",
    //     "It streamlines workflow management and corporate action processing",
    //     "Client-designed work queues allow the client to identify exception situations that should be addressed by operations personnel",
    //     "Client-tailored risk measurements create a risk value for each event that clearly identifies where risk conditions exist",
    //     "Modular software architecture means clients can easily add functionality that supports any change to their operations",
    //     "Dynamic Work Queues and Searches",
    //     "Audit Trail",
    //     "Data Comparison",
    //     "Event Delivery",
    //     "Event Templates",
    //     "Risk Templates",
    //     "Exception Processing",
    //     "Risk Measurement",
    //     "Secure Site",
    //   ],
    //   Paragraph: [
    //     "The system provides exceptional corporate action handling and has scalability at its core through proper risk templates and workflow automation. Reach out to us to create a robust and efficient Corporate Action system.",
    //   ],
    //   Diagram: Diagram6,
    //   associatedFilters: ["Equities", "Fixed Income"],
    //   Link: "corporate-action-management",
    //   associatedCards: [
    //     "Global Corporate Action Event Generation & Reporting System",
    //     "Lite Equity Order Management System (OMS)",
    //     "Fixed Income Electronic Trade Processing System For Clearing, Settlement and Reporting",
    //   ],
    // },
    {
      id: 6,
      Name: "How to succeed as a Fintech Entrepreneur?",
      TempHeadline: [
        "Fintech entrepreneurs differ from the stereotypical young Silicon Valley dropout. They are seasoned professionals who have achieved success in their careers. These entrepreneurs, often business-savvy, harbor innovative ideas that can revolutionize the global financial industry. Their goals? To simplify financial processes, boost earnings, or achieve both.",
        <span>
          However, launching and managing such entrepreneurial endeavors is fraught with challenges. While these entrepreneurs may grasp technology, they aren't necessarily tech experts. Entrepreneurs embarking on fintech ventures seek a winning combination: domain expertise, technical prowess, cost efficiency, robust infrastructure, swift time-to-market, and reliable support. <b>Enter Fintech Global Center (FGC)—</b> a hub of excellence that caters to established financial institutions and
          disruptive fintech entrepreneurs alike, building, operating, and managing secures resilient financial systems efficiently and affordably.
        </span>,
      ],
      Highlight: [],
      Paragraph: [
        <b className="h2" style={{ fontWeight: "700", opacity: "0.8" }}>
          {" "}
          We're here to empower your entrepreneurial journey!
        </b>,
      ],
      Diagram: "",
      associatedFilters: ["Article"],
      Link: "how-to-succeed-as-a-fintech-entrepreneur",
      associatedCards: ["Cloud Based Fixed Income Trading System", "Comprehensive Fixed Income Market Data and Analytics System", "Global Corporate Action Event Generation & Reporting System"],
      TempHead: ["Domain Knowledge", "Tech Experts", "Cost Efficiency", "Infrastructure Build & Deploy", "Time to Market", "Support & Operations", "Branding, UI/UX, Website", "Ecosystem Partners", "Collaborative Approach", "Ready to take your fintech dream to market?"],
      TempContent: [
        "For timely and efficient delivery, having relevant domain experience is crucial. FGC's team comprises industry professionals with a deep understanding of the global finance business, with decades of experience. This not only accelerates the idea realization process but also enhances product ideation through insightful discussions.",
        "Equally crucial is having a team experienced in building performant, reliable, robust, and scalable solutions. FGC's team has been developing fintech systems for over three decades.",
        "Entrepreneurs value timely and cost-effective processes. FGC has had an India Dev Center for nearly two decades, delivering cost efficiencies to entrepreneurs.",
        "Success hinges on having a team that can build a secure, resilient, and disaster-recovered infrastructure, updated and maintained constantly. FGC has been building, deploying, and managing fintech infrastructure for decades.",
        "Speed is essential for bringing ideas to market, reducing both actual and opportunity costs. In most cases, FGC has helped bring ideas to life, typically launching within three to six months from engagement.",
        "After a product goes live, having a domain expert client support & operations team is crucial. FGC has supported fintech customers globally for over two decades with its practical 24x7 support team.",
        "These elements are crucial for business success. FGC has an experienced design team to help entrepreneurs create branding, UI/UX, and websites that are aesthetically appealing and match the brand's persona, targeting the intended demographic.",
        "Bringing an idea to life requires support from several third-party vendors. FGC, through its partners and fintech vendor knowledge, helps establish better partnerships for a cheaper and faster launch.",
        "While Fintech Global Center (FGC) has been the driving force behind numerous entrepreneurial ventures, it also thrives on collaborative synergy. We can be your one-stop IT shop and work with existing teams to empower your success as well.",
        <span>
          Explore our diverse portfolio of projects and contact us today at Fintech Global Center (
          <a href="https://fintechglobal.center" className="article-link">
            www.fintechglobal.center
          </a>
          ) - your trusted partner in fintech innovation with decades of proven success.
        </span>,
      ],
    },
    {
      id: 7,
      Name: "Global Corporate Action Event Generation & Reporting System",
      Headline: ["Built a Corporate Action Management and Processing System for a Large Global Bank's Prime Broker Business."],
      Highlight: [
        "Corporate Action Feeds from multiple sources is consumed and creates a Golden record based on various complex criteria",
        "It takes accounts and positions, and generates SWIFT messages, Emails, and files for further processing to the client's systems providing them with personalized event reports of mandatory and voluntary events.",
        "Confirmation of actions taken on the user's behalf, based on various business and client needs",
        "The system is built to run in the Cloud and can be used as an installed or hosted solution as a service, utilizing cutting edge technology",
        "The system provides exceptional corporate action handling and has scalability at its core through proper risk templates and workflow automation.",
        "Low operational risk",
        "Customized event Reporting",
        "Event Templates",
        "Risk Templates",
      ],
      Paragraph: ["This comprehensive system aims to help create a robust and efficient Corporate Action Event Generation & Reporting System. Reach out to us for Event Generation & Reporting System."],
      Diagram: Diagram7,
      associatedFilters: ["Equities", "Fixed Income"],
      Link: "corporate-action-events",
      associatedCards: ["Global Corporate Action System for Prime Broker Services", "Lite Equity Order Management System (OMS)", "Global Asset Class Neutral Trading System"],
    },
    {
      id: 8,
      Name: "Global Multi-Asset Class Trade Management System",
      Headline: ["Built a cloud-based web application provides complete trade management flow for multiple asset classes under the same system."],
      Highlight: [
        "The system supports a wide range of asset classes and sub-asset class trades, including Fixed Income, FX, Futures, and CFDs.",
        "Client onboarding processes are customizable, allowing for tailored configurations based on settlement and custodian details under different conditions.",
        "A comprehensive security master is available for Fixed Income, Equity, FX, and CFDs within the system.",
        "Independent modules seamlessly connect with various venues and clearers to collect trades and process them centrally.",
        "End-to-end automation streamlines trade processing, from receiving and enrichment to SWIFT sending, confirmation, and regulatory reporting.",
        "The system accommodates multiple branches or locations, each with its own unique set of rules and regulatory requirements.",
        "Corporate actions such as coupon receipt, principal paydown, and internal transfers are processed, with Position and PnL calculated for individuals and management.",
        "Payouts are generated with consideration for corporate actions, charges, carry forwards, and custom rules for individuals.",
        "Management reports, including Start of Day, BI, Position, Payout, and Daily PnL reports, are sent daily or monthly.",
        "Operations have access to various screens and dashboards for monitoring system status and performing dynamic activities.",
      ],
      Paragraph: "",
      Diagram: Diagram8,
      associatedFilters: ["Equities", "FX", "Fixed Income"],
      Link: "multi-asset-trade-management-system",
      associatedCards: ["Retail Bond Trading System", "Cloud Based Fixed Income Trading System", "Global Asset Class Neutral Trading System"],
    },
    {
      id: 9,
      Name: "Global Asset Class Neutral Trading System",
      Headline: ["A resilient Cloud based web application for security trading. It takes multiple inputs, connects with various data sources, processes orders and generates trades based on different execution algorithms. Post trade the trade is sent to the counterparties, custodian and clearer for reporting, settlement and clearing."],
      Highlight: [
        "The system supports a wide range of asset classes and sub-asset class trades, I.e. Equities, Fixed Income, FX (Spot, Forward, Swap), CFD, Futures",
        "Extensive security master for Bonds, Equities, FX, Futures, CFDs within the system.",
        "The system handles End-to-end automation and streamlines trade processing including market and settlement, Regulatory reporting and clearing instruction and confirmation.",
        "Built using Angular, Node.js, JAVA and MySQL and hosted on AWS.",
        "Having 100% disaster recovery and resiliency across zones and regions.",
        "Robust security features, including encryption, multi-factor authentication, and secure data storage, to protect user data and transactions.",
        "Operations have access to various screens and dashboards for monitoring system status and performing dynamic activities.",
      ],
      Paragraph: "",
      Diagram: Diagram9,
      associatedFilters: ["Equities", "FX", "Fixed Income", "Interest Rate"],
      Link: "trade-management-system",
      associatedCards: ["Retail Bond Trading System", "Cloud Based Fixed Income Trading System", "Global Inter Dealer Broker Asset Class Neutral Trading System"],
    },
    {
      id: 10,
      Name: "Advanced NDF, FX, FX Options, and Interest Rate Trading Systems",
      Headline: ["Architected, designed and built Next Gen NDF, FX, FX Options and Interest Rate Trading Solutions."],
      Highlight: [
        "Advanced algorithms for intelligent trade execution and optimal order routing.",
        "Seamless end-to-end automation from trade execution to settlement, reducing manual intervention and operational risks.",
        "Robust risk management tools for monitoring and mitigating market risk, credit risk, and ensuring compliance.",
        "Timely and accurate trade reporting to external entities for compliance with regulatory requirements.",
        "Standardized and secure communication, ensuring compatibility with various trading partners.",
        "Middleware facilitates communication between front, middle, and back-office systems, ensuring scalability and flexibility.",
        "Advanced algorithms for dynamic trade execution, leveraging real-time market data for optimal results.",
        "STP ensures end-to-end automation, from trade initiation to settlement, streamlining processes and reducing latency.",
        "Integrated risk management tools covering market, credit, and compliance risks, providing a holistic risk assessment.",
        "A commitment to staying ahead of regulatory changes, ensuring compliance and market integrity.",
        "Middleware facilitates seamless interoperability between diverse components, systems, and external entities.",
      ],
      Features: [],
      Paragraph: ["This comprehensive system aims to help create an easy-to-use trading environment with state-of-the-art matching algorithms and automated flow from execution to settlement.", "Reach out to us for any end-to-end trading system. We have been implementing trading systems including market data analytics, trading, clearing, settlement, reg reporting, PnL, risk, etc., for multiple decades."],
      Diagram: Diagram10,
      associatedFilters: ["FX", "Fixed Income", "Interest Rate"],
      Link: "advanced-trading-systems",
      associatedCards: ["Cloud Based Fixed Income Trading System", "Global Multi-Asset Class Trade Management System", "Global Asset Class Neutral Trading System"],
    },
    {
      id: 11,
      Name: "Global Inter Dealer Broker Asset Class Neutral Trading System",
      Headline: ["A secure, real-time and advanced trading system for Inter Dealer Broker Asset Class services."],
      Highlight: [
        "Secure authentication and authorization. Differentiated user roles for brokers, traders, and administrators.",
        "Real-time market data feeds for accurate pricing.",
        "Dynamic order routing and execution capabilities with flexible order types (market, limit, stop, etc.).",
        "Advanced exposure monitoring and control which supports customizable risk limits and alerts.",
        "Efficient trade matching engine and automated confirmation process.",
        "Seamless integration with clearing houses, ensuring compliance with financial regulations.",
        "Designed for high scalability and low-latency performance. Seamless integration with external systems and support for FIX protocol.",
        "Intuitive and customizable dashboards and reporting tools for effective decision-making.",
        "Dynamic credit limits based on risk profiles.",
        "Functionality for auctions and defined trading sessions, also supports quoting processes like RFQ, RFS.",
        "Tools for trade validation, enrichment, and risk management.",
        "Functionalities for trade settlement, confirmation, and reconciliation.",
        "Business intelligence tools for reporting and visualizations.",
        "Operations dashboard for real-time monitoring and alerting.",
      ],
      Paragraph: "",
      Diagram: Diagram11,
      associatedFilters: ["Equities", "FX", "Interest Rate", "Commodity", "Energy"],
      Link: "inter-dealer-broker-trading-system",
      associatedCards: ["Global Asset Class Neutral Trading System", "Cloud Based Fixed Income Trading System", "Global Multi-Asset Class Trade Management System"],
    },
    // {
    //   id: 12,
    //   Name: "How to succeed as a Fintech Entrepreneur?",
    //   TempHeadline: [
    //     "Fintech entrepreneurs differ from the stereotypical young Silicon Valley dropout. They are seasoned professionals who have achieved success in their careers. These entrepreneurs, often business-savvy, harbor innovative ideas that can revolutionize the global financial industry. Their goals? To simplify financial processes, boost earnings, or achieve both.",
    //     <span>However, launching and managing such entrepreneurial endeavors is fraught with challenges. While these entrepreneurs may grasp technology, they aren't necessarily tech experts. Entrepreneurs embarking on fintech ventures seek a winning combination: domain expertise, technical prowess, cost efficiency, robust infrastructure, swift time-to-market, and reliable support. <b>Enter Fintech Global Center (FGC)—</b> a hub of excellence that caters to established financial institutions and disruptive fintech entrepreneurs alike, building, operating, and managing secures resilient financial systems efficiently and affordably.</span>,
    //   ],
    //   Highlight: [],
    //   Paragraph: [<b className="h2" style={{fontWeight: "700", opacity: "0.8"}}> We're here to empower your entrepreneurial journey!</b>],
    //   Diagram: "",
    //   associatedFilters: ["Article"],
    //   Link: "how-to-succeed-as-a-fintech-entrepreneur",
    //   associatedCards: ["Cloud Based Fixed Income Trading System", "Comprehensive Fixed Income Market Data and Analytics System", "Global Corporate Action System for Prime Broker Services"],
    //   TempHead: [
    //     "Domain Knowledge",
    //     "Tech Experts",
    //     "Cost Efficiency",
    //     "Infrastructure Build & Deploy",
    //     "Time to Market",
    //     "Support & Operations",
    //     "Branding, UI/UX, Website",
    //     "Ecosystem Partners",
    //     "Collaborative Approach",
    //     "Ready to take your fintech dream to market?",
    //   ],
    //   TempContent: [
    //     "For timely and efficient delivery, having relevant domain experience is crucial. FGC's team comprises industry professionals with a deep understanding of the global finance business, with decades of experience. This not only accelerates the idea realization process but also enhances product ideation through insightful discussions.",
    //     "Equally crucial is having a team experienced in building performant, reliable, robust, and scalable solutions. FGC's team has been developing fintech systems for over three decades.",
    //     "Entrepreneurs value timely and cost-effective processes. FGC has had an India Dev Center for nearly two decades, delivering cost efficiencies to entrepreneurs.",
    //     "Success hinges on having a team that can build a secure, resilient, and disaster-recovered infrastructure, updated and maintained constantly. FGC has been building, deploying, and managing fintech infrastructure for decades.",
    //     "Speed is essential for bringing ideas to market, reducing both actual and opportunity costs. In most cases, FGC has helped bring ideas to life, typically launching within three to six months from engagement.",
    //     "After a product goes live, having a domain expert client support & operations team is crucial. FGC has supported fintech customers globally for over two decades with its practical 24x7 support team.",
    //     "These elements are crucial for business success. FGC has an experienced design team to help entrepreneurs create branding, UI/UX, and websites that are aesthetically appealing and match the brand's persona, targeting the intended demographic.",
    //     "Bringing an idea to life requires support from several third-party vendors. FGC, through its partners and fintech vendor knowledge, helps establish better partnerships for a cheaper and faster launch.",
    //     "While Fintech Global Center (FGC) has been the driving force behind numerous entrepreneurial ventures, it also thrives on collaborative synergy. We can be your one-stop IT shop and work with existing teams to empower your success as well.",
    //     <span>
    //       Explore our diverse portfolio of projects and contact us today at Fintech Global Center (<a href="https://fintechglobal.center">www.fintechglobal.center</a>) - your trusted partner in
    //       fintech innovation with decades of proven success.
    //     </span>,
    //   ],
    // },
  ];

  const { pLink } = useParams();
  const navigate = useNavigate();

  const pName = projectList.find((project) => project.Link === pLink);

  const pId = pName?.id ?? 0;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNextClick = () => {
    navigate(`/projects/${projectList[(pId + 1 + projectList.length) % projectList.length].Link}`);
    scrollToTop();
  };

  const handlePrevClick = () => {
    navigate(`/projects/${projectList[(pId - 1 + projectList.length) % projectList.length].Link}`);
    scrollToTop();
  };

  const project = projectList[pId];

  const Description = ({ Name, associatedFilters, Headline, Highlight, Paragraph, Diagram, TempHead, TempContent, TempHeadline }) => {
    const isEmpty = (value) => {
      return Array.isArray(value) ? value.length === 0 : !value;
    };

    return (
      <>
        <Helmet>
          <title>{Name} - Fintech Global Center</title>
          <meta name="description" content={Headline ? Headline[0] : "Explore our fintech projects at Fintech Global Center, from innovative solutions to industry advancements. Join our finance revolution."} />
          <link rel="canonical" href={`https://fintechglobal.center/projects/${pLink}`} />
        </Helmet>
        <div className="project-header-container">
          <div className="container">
            <div className="mb-5">
              <a className="text-white pt-5" id="project-list-link" style={{ fontWeight: "700" }} href="/projects">
                <ArrowLeftShort size={25} />
                Back to All Projects
              </a>
            </div>
            <div className="container-fluid mt-5">
              <div className="row">
                <div className="category-list" style={{ padding: "0px" }}>
                  {associatedFilters.map((filter, index) => (
                    <div className="category-list-item" key={index}>
                      {filter}
                    </div>
                  ))}
                </div>
                <div className="row mt-2">
                  <h1 className="h2" style={{ padding: "0", color: "white", fontWeight: "700" }}>
                    {Name}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-0 px-lg-5 px-md-3 px-sm-1">
          <div className="row justify-content-center align-items-center my-auto">
            <div className="d-none d-sm-block p-0 w-auto mx-auto my-auto col-1">
              <div className="project-details-action">
                <div onClick={handlePrevClick}>
                  <ChevronLeft size={25} /> {""}
                </div>
              </div>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-10">
              <div className="project-details-item-headline">
                {!isEmpty(Headline) && <div className="project-details-item-headline-tag">Headline:</div>}
                {!isEmpty(Headline) && (
                  <div className="project-details-item-headline-list">
                    {Headline.map((res, i) => {
                      return <p key={i}>{res}</p>;
                    })}
                  </div>
                )}

                {!isEmpty(TempHeadline) && (
                  <div className="project-details-item-headline-list">
                    {TempHeadline.map((res, i) => {
                      return <p key={i}>{res}</p>;
                    })}
                  </div>
                )}
              </div>
              <div className="project-details-item-highlight">
                {!isEmpty(Highlight) && <div className="project-details-item-headline-tag">Key Highlights:</div>}
                <div className="project-details-item-headline-list mb-0">
                  {!isEmpty(Highlight) && (
                    <ul>
                      {Highlight.map((res, i) => {
                        return <li key={i}>{res}</li>;
                      })}
                    </ul>
                  )}
                </div>
              </div>

              {!isEmpty(TempHead) && !isEmpty(TempContent) && (
                <div className="project-details-item-headline mb-5">
                  {TempHead.map((headItem, index) => (
                    <div key={index} className="project-details-item-paragraph">
                      <div className="project-details-item-headline-tag mb-3">{headItem}:</div>
                      <div className="project-details-item-paragraph-text">{TempContent[index]}</div>
                    </div>
                  ))}
                </div>
              )}

              {!isEmpty(Paragraph) && (
                <div className="project-details-item-paragraph">
                  {Paragraph && (
                    <div className="project-details-item-paragraph-text">
                      {Paragraph.map((res, i) => {
                        return <p key={i}>{res}</p>;
                      })}
                    </div>
                  )}
                </div>
              )}

              {!isEmpty(Diagram) && (
                <div className="project-details-item-diagram mt-5">
                  <img className="object-fit-contain" src={Diagram} alt={Name} />
                </div>
              )}
            </div>
            <div className="d-none d-sm-block p-0 w-auto mx-auto my-auto col-1">
              <div className="project-details-action" onClick={handleNextClick}>
                {""} <ChevronRight size={25} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Projects - Fintech Global Center</title>
        <meta name="description" content="Explore our fintech projects at Fintech Global Center, from innovative solutions to industry advancements. Join our finance revolution." />
        <link rel="canonical" href="https://fintechglobal.center/projects" />
      </Helmet>
      <NavBar logo={logoBlack} />
      <div id="projects" className="mb-1 pb-1 mb-lg-5 pb-lg-5 mb-md-3 pb-md-3">
        <div className="col">
          <Description {...project} />
        </div>
      </div>
      <div className="associatedCards pt-2" style={{ background: "rgba(247, 247, 251, 1)" }}>
        <div className="container mb-0">
          <div className="mb-4" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <h3 style={{ fontWeight: "700", marginBottom: "0px", marginRight: "20px" }}>More Projects</h3>
            <a className="text-end text-dark" id="project-list-link" style={{ fontWeight: "700", textWrap: "balance" }} href="/projects">
              View All Projects
              <ArrowRightShort size={25} />
            </a>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 pb-5 g-4 justify-content-center">
            {project.associatedCards.map((cardName) => {
              const card = projectList.find((item) => item.Name === cardName);
              if (card) {
                return (
                  <div className="col" key={card.id}>
                    <Card {...card} />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
